<template>
  <div class="logdetails" v-if="info != ''">
    <div class="auto-table-flex">
      <div class="ctns">
        <div class="divtitle">
          <div class="itms">
            品名：{{ info.supplier_order_detail.product.title }}({{
              info.supplier_order_detail.product.sku_code
            }}) 供货商：{{ info.supplier_order.supplier.title }} 采购：{{
              info.supplier_order.supplier.buyer.fullname
            }}
          </div>
          <div class="itms rt">
            供货单编号：{{ info.supplier_order.supplier_order_no }} 入库时间：{{
              $empty.time(info.review_time)
            }}
          </div>
        </div>
        <el-table
          :data="info.order_entruck_detail"
          border
          style="width: 100%"
          :header-cell-style="{ color: '#333333', fontSize: '16px' }"
        >
          <el-table-column prop="date" align="center" label="物流线路">
            <template slot-scope="scope">
              {{ $empty.empty(scope.row.delivery.name) }}
            </template>
          </el-table-column>
          <!-- <el-table-column prop="name" label="单价" align="center">
          </el-table-column> -->
          <el-table-column prop="origin_number" align="center" label="数量">
            <template slot-scope="scope">
              {{ $empty.empty(scope.row.origin_number) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="review_number"
            align="center"
            label="确认上车数量"
          >
            <template slot-scope="scope">
              {{ $empty.empty(scope.row.review_number) }}
            </template>
          </el-table-column>
          <el-table-column prop="reason" align="center" label="原因备注">
            <template slot-scope="scope">
              {{ $empty.empty(scope.row.reason) }}
            </template>
          </el-table-column>
        </el-table>
        <div class="shij mt">合计送货数量：{{ info.origin_number }}</div>
        <div class="shij">实际送货数量：{{ info.review_number }}</div>
        <div class="si">
          <div class="item">制单员：</div>
          <div class="item">{{ info.create_order_user_name }}</div>
          <div class="item">核单员：</div>
          <div class="item rt">{{ $empty.empty(info.review_user_name) }}</div>
        </div>
      </div>
    </div>
    <div class="btn">
      <el-button @click="fanhui">返回</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "logdetails",
  components: {},
  data() {
    return {
      tableData: [],
      info: "",
    };
  },
  created() {
    this.hqinfo();
  },
  methods: {
    hqinfo() {
      this.$api.logistics
        .entruckdetail({ id: this.$route.query.id })
        .then((res) => {
          console.log(res, "获取数据");
          this.info = res.data;
        });
    },
    fanhui() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.logdetails {
  height: 100%;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  background: #ffffff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  box-sizing: border-box;
  .auto-table-flex {
    flex-grow: 1;
    overflow-y: auto;
    .ctns {
      padding: 23px 12px;
      box-sizing: border-box;
    }
  }
  .btn {
    border-top: 1px solid #e8e8e8;
    display: flex;
    padding: 14px 0;

    box-sizing: border-box;
    justify-content: center;
  }
  .shij {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 56px;
    border: 1px solid #666666;
    border-bottom: 0;
  }
  .mt {
    border-top: 0;
  }

  .divtitle {
    display: flex;
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    .itms {
      flex: 1;
      height: 89px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #666666;
      border-right: 0;
    }
  }
  .si {
    display: flex;
    .item {
      flex: 1;
      padding: 18px 0;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #666666;
      border-right: 0;
    }
  }

  /**
改变边框颜色
 */
  /deep/ .el-table--border,
  .el-table--group {
    border: 1px solid #666 !important;
  }
  /**
改变表格内竖线颜色
 */
  /deep/ .el-table--border td,
  .el-table--border th,
  .el-table__body-wrapper
    .el-table--border.is-scrolling-left
    ~ .el-table__fixed {
    border-right: 1px solid #666 !important;
  }
  /**
改变表格内行线颜色
 */
  /deep/ .el-table td,
  .el-table th.is-leaf {
    border-bottom: 1px solid #666 !important;
  }
  /deep/ .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: 1px solid #666 !important;
  }

  /deep/ .el-table thead tr th {
    border-color: #666;
  }
  .rt {
    border-right: 1px solid #666666 !important;
  }
}
</style>
