var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.info != ""
    ? _c("div", { staticClass: "logdetails" }, [
        _c("div", { staticClass: "auto-table-flex" }, [
          _c(
            "div",
            { staticClass: "ctns" },
            [
              _c("div", { staticClass: "divtitle" }, [
                _c("div", { staticClass: "itms" }, [
                  _vm._v(
                    " 品名：" +
                      _vm._s(_vm.info.supplier_order_detail.product.title) +
                      "(" +
                      _vm._s(_vm.info.supplier_order_detail.product.sku_code) +
                      ") 供货商：" +
                      _vm._s(_vm.info.supplier_order.supplier.title) +
                      " 采购：" +
                      _vm._s(_vm.info.supplier_order.supplier.buyer.fullname) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "itms rt" }, [
                  _vm._v(
                    " 供货单编号：" +
                      _vm._s(_vm.info.supplier_order.supplier_order_no) +
                      " 入库时间：" +
                      _vm._s(_vm.$empty.time(_vm.info.review_time)) +
                      " "
                  ),
                ]),
              ]),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.info.order_entruck_detail,
                    border: "",
                    "header-cell-style": { color: "#333333", fontSize: "16px" },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "date", align: "center", label: "物流线路" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$empty.empty(scope.row.delivery.name)
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1334244931
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "origin_number",
                      align: "center",
                      label: "数量",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$empty.empty(scope.row.origin_number)
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      626011998
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "review_number",
                      align: "center",
                      label: "确认上车数量",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$empty.empty(scope.row.review_number)
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      17922896
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "reason",
                      align: "center",
                      label: "原因备注",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$empty.empty(scope.row.reason)) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2354171634
                    ),
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "shij mt" }, [
                _vm._v("合计送货数量：" + _vm._s(_vm.info.origin_number)),
              ]),
              _c("div", { staticClass: "shij" }, [
                _vm._v("实际送货数量：" + _vm._s(_vm.info.review_number)),
              ]),
              _c("div", { staticClass: "si" }, [
                _c("div", { staticClass: "item" }, [_vm._v("制单员：")]),
                _c("div", { staticClass: "item" }, [
                  _vm._v(_vm._s(_vm.info.create_order_user_name)),
                ]),
                _c("div", { staticClass: "item" }, [_vm._v("核单员：")]),
                _c("div", { staticClass: "item rt" }, [
                  _vm._v(_vm._s(_vm.$empty.empty(_vm.info.review_user_name))),
                ]),
              ]),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "btn" },
          [_c("el-button", { on: { click: _vm.fanhui } }, [_vm._v("返回")])],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }